h1, h2 {
  margin: 0;
}

/*
 * Setting theming color classes
 */
.primary {
  &.color {
    color: var(--ecmo-primary-color, grey);
  }

  &.background {
    background-color: var(--ecmo-primary-color, grey);
  }
}

.secondary {
  &.color {
    color: var(--ecmo-secondary-color, grey);
  }

  &.background {
    background-color: var(--ecmo-secondary-color, grey);
  }
}

/*
 * Setting the layout classes
 */
.container {
  font-size: 1.6rem;
  padding: 1rem;
}

.row {
  flex-direction: row;

  &.center {
    justify-content: center;

    &.text-center {
      text-align: center;
    }

    h1 {
      font-size: 2rem;
      text-align: center;
    }
  }
}

.column {
  flex-direction: column;

  &.center {
    align-items: center;
  }
}

.row, .column {
  display: flex;

  &.gap-1 {
    gap: 1rem
  }

  .item {
    flex: 1;

    &.one {
      flex: 1;
    }

    &.two {
      flex: 2;
    }

    &.three {
      flex: 3;
    }

    &.four {
      flex: 4;
    }

    &.five {
      flex: 5;
    }

    &.six {
      flex: 6;
    }

    &.seven {
      flex: 7;
    }

    &.height {
      flex: 8;
    }

    &.nine {
      flex: 9;
    }

    &.ten {
      flex: 10;
    }

    &.eleven {
      flex: 11;
    }
  }
}


@mixin margin($rem) {
  &-#{$rem} {
    margin-top: #{$rem}rem;
  }
}

.space-top {
  @for $_ from 1 through 10 {
    @include margin($_)
  }
}

/*
 * Setting default font-size to 10px so 1rem = 10px in default
 */
html {
  color: var(--ecmo-theme-primary-contrast-50, #0B3042);

  --mdc-typography-button-font-size: 1.6rem;
  --mdc-typography-button-letter-spacing: 0.1rem;
  --mdc-shape-small: 0.4rem;
}

/*
 * Correcting Material for our project
 */
.mat-mdc-form-field {
  width: 100%;

  .mat-mdc-form-field-subscript-wrapper {
    display: flex;

    .mat-mdc-form-field-error-wrapper, .mat-mdc-form-field-hint-wrapper {
      position: relative;
      width: 100%;
      padding: 0 var(--mdc-shape-small);
    }
  }

  &-icon-prefix>mat-icon.mat-icon, &-icon-suffix>mat-icon.mat-icon {
    padding: 0 0.5rem;
  }
}

.mat-mdc-form-field-infix {
  display: flex;
}

button.mat-mdc-outlined-button:not(:disabled) {
  border-color: var(--ecmo-theme-primary-border);
}

button.mdc-button > .mat-icon {
  $mat-icon-size: 2rem;

  width: $mat-icon-size;
  height: $mat-icon-size;
  font-size: $mat-icon-size;
  margin: 0;
}


.mat-mdc-checkbox {
  .mdc-form-field {
    --mdc-typography-body2-font-size: 1.6rem;
  }
}

.mat-mdc-radio-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 1rem;

  .mdc {
    &-radio {
      display: none;
    }

    &-form-field {
      display: flex;
      cursor: inherit;

      &>label {
        padding: 0;
        cursor: inherit;
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex: 1;
        justify-content: center;
        color: var(--ecmo-theme-primary-contrast-50);

        &:has(>.mat-icon) {
          justify-content: flex-start;
          padding: 1rem;
        }

        .mat-icon {
          font-size: 6rem;
          height: unset;
          width: unset;
        }
      }
    }
  }

  &.icon-choices {
    .mdc-form-field {
      height: fit-content;
    }
  }

  .mat-mdc-radio-button {
    display: grid;
    min-height: 4rem;
    border-radius: var(--mdc-shape-small);
    box-sizing: border-box;
    padding: 0.5rem;

    &:not(.radio-select--disabled) {
      cursor: pointer;
      border: 0.1rem solid var(--ecmo-theme-primary-border);

      &:hover, &.mat-mdc-radio-checked {
        background-color: var(--ecmo-theme-primary-100);
        color: var(--ecmo-theme-primary-contrast-100);
      }

      &.mat-mdc-radio-checked {
        border: 0.15rem solid var(--ecmo-theme-primary-500);
      }
    }

    &.radio-select {
      &--disabled {
        cursor: default;
        border: 0.1rem solid #0000000f;
      }
    }
  }
}

ecmo-number-input {
  .mat-mdc-form-field-hint-spacer {
    display: none;
  }

  .mat-mdc-form-field-infix {
    width: unset;
  }

  .mdc-text-field--invalid {
    button.mat-mdc-outlined-button:not(:disabled) {
      border-color: var(--mdc-theme-error, #f44336);
    }

    .mat-mdc-outlined-button:not(:disabled) {
      color: var(--mdc-theme-error, #f44336);
    }
  }
}

[file-input] {
  .mdc-notched-outline {
    &__leading {
      border-style: dashed none dashed dashed;
    }

    &__notch {
      border-style: dashed none;
    }

    &__trailing {
      border-style: dashed dashed dashed none;
    }
  }

  .mat-mdc-text-field-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field {
    &-flex {
      justify-content: center;
    }

    &-infix {
      display: flex;
      flex: unset;
      justify-content: center;
    }

    &-suffix {
      display: none;
    }

    &-icon-prefix {
      color: var(--ecmo-grey-text);
    }
  }
}

.mdc-text-field {
  &--outlined:not(&--disabled, &--invalid) .mdc-notched-outline {
    &__leading, &__notch, &__trailing {
      border-color: var(--ecmo-theme-primary-border);
    }
  }
}

.sticky-wrapper {
  .mdc-button {
    &--unelevated {
      padding: 2rem 3rem;
      font-weight: 900;
    }
  }
}

a, button {
  &.mat-mdc-unelevated-button {
    &.mat-mdc-button-base {
      height: 4.8rem;
    }

    .mdc-button {
      &__label {
        z-index: unset;
        text-align: center;
      }
    }
  }

  &.no-border {
    border: none;
    text-decoration: underline;
  }
}

.mat-mdc-form-field-hint {
  font-style: italic;
  color: var(--ecmo-grey-text);
}

.nav.top .partner ecmo-img img {
  margin-left: 0;
}

.mat-mdc-checkbox.inverted {
  width: 100%;

  .mdc-form-field {
    flex-direction: row-reverse;
  }
}
